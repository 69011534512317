import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Message } from "element-ui";

// 页面路由
const pageRoutes = [
  { path: '/login', name: 'login', component: () => import('@/views/pages/login'), meta: { title: '登录' } }
]

// 模块路由
const moduleRoute = {
  path: '/',
  redirect: { name: 'login' },
  name: 'main',
  component: () => import('@/views/main'),
  meta: { title: '主入口' }
}

Vue.use(VueRouter)

const router = new VueRouter({
  routes: pageRoutes.concat(moduleRoute)
})

router.beforeEach((to, from, next) => {
  if (to.name == 'login' || window.SITE_CONFIG['dynamicMenuRoutesHasAdded']) return next();


  let userdata = store.state.userdata || ''

  if (userdata && userdata.menus) {
    window.SITE_CONFIG['menuList'] = userdata.menus
    fnAddDynamicMenuRoutes(window.SITE_CONFIG['menuList'])
    next({ ...to, replace: true })
  } else {
    Message.error('登录超时，请重新登录')
    next({ name: 'login' })
  }
})

var fnAddDynamicMenuRoutes = (menuList, routers = []) => {
  var temp = [];
  for (var i = 0; i < menuList.length; i++) {
    if (menuList[i].children && menuList[i].children.length > 0) {
      temp = temp.concat(menuList[i].children)
    }
    routers.push(menuList[i])
  }

  if (temp.length >= 1) {
    return fnAddDynamicMenuRoutes(temp, routers)
  }

  routers.forEach(ele => {
    if (ele.type === 0 && ele.url) {
      router.addRoute('main', {
        name: ele.rName, path: ele.url, component: () => import('@/views/modules' + ele.url), meta: {
          bgColor:
            (ele.rName == 'home' || ele.rName == 'visual') ? 'bg-tp' : ''
        }
      })
    }
  })
  // menuList.forEach(ele => {
  //   // 创建路由的子路由
  //   if (ele.children && ele.children.length > 0) {
  //     ele.children.forEach(child => {
  //       router.addRoute('main', {
  //         name: child.rName, path: child.url, component: () => import('@/views/modules' + child.url)
  //       })
  //     })
  //   }
  //   // 创建路由
  //   else {
  //     if (ele.url) {
  //       router.addRoute('main', {
  //         name: ele.rName, path: ele.url, component: () => import('@/views/modules' + ele.url), meta: {
  //           bgColor:
  //             (ele.rName == 'home' || ele.rName == 'visual') ? 'bg-tp' : ''
  //         }
  //       })
  //     }
  //   }
  // })
  // router.hasRou
  window.SITE_CONFIG['dynamicMenuRoutesHasAdded'] = true
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
export default router
