import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import api from './utils/request-api'
import { myMixin } from '@/mixins/mixins.js'

import '@/icons'
import '@/assets/scss/bh.scss'

import echarts from '@/echarts/index.js'

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

Vue.prototype.$echarts = echarts

Vue.use(ElementUI)

Vue.prototype.$http = api

Vue.config.productionTip = false

Vue.mixin(myMixin)


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
