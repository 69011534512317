import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedstate from 'vuex-persistedstate'

Vue.use(Vuex)

const PERSIST_PATHS = ['userdata', 'token']
export default new Vuex.Store({
  plugins: [createPersistedstate({
    key: 'beihangVuex',
    paths: PERSIST_PATHS
  })],
  state: {
    userdata: '',
    token: '',
    sidebarFold: false,
    tipList: null
  },
  mutations: {
    // 保存登录用户信息
    saveUserdata(state, val) {
      state.userdata = val || ''
      state.token = val ? (val.token || '') : ''
    },

    // 保存待办项
    saveTipList(state, val) {
      state.tipList = val || null;
    }
  },
  actions: {
  },
  modules: {
  }
})
