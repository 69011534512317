import { get, post } from "./request";

const api = {};

// 用户
api.user = {
  login: (data) => post("/user/login", data),
  chart: (data) => get("/user/chartData", data)
};

// 首页数据
api.indexData = {
  info: (data) => get("/user/indexData", data),
};

// 菜单
api.menu = {
  list: (data) => get("/menu/list", data),
  info: (data) => get("/menu/info", data),
  save: (data) => post("/menu/save", data),
  update: (data) => post("/menu/update", data),
  delete: (data) => get("/menu/delete", data),
};

// 角色
api.role = {
  power: (data) => get("/role/power", data),
  list: (data) => get("/role/list", data),
  info: (data) => get("/role/info", data),
  save: (data) => post("/role/save", data),
  update: (data) => post("/role/update", data),
  delete: (data) => get("/role/delete", data),
};

// 课件
api.courseware = {
  list: (data) => get("/courseware/list", data),
  info: (data) => get("/courseware/info", data),
  save: (data) => post("/courseware/save", data),
  update: (data) => post("/courseware/update", data),
  delete: (data) => get("/courseware/delete", data),
};

// 题目
api.question = {
  list: (data) => get("/question/list", data),
  info: (data) => get("/question/info", data),
  save: (data) => post("/question/save", data),
  update: (data) => post("/question/update", data),
  delete: (data) => get("/question/delete", data),
  batchSave: (data) => post('/question/batchSave', data)
};

// 试卷
api.exam = {
  list: (data) => get("/exam/list", data),
  info: (data) => get("/exam/info", data),
  save: (data) => post("/exam/save", data),
  update: (data) => post("/exam/update", data),
  delete: (data) => get("/exam/delete", data),
};

// 部门
api.department = {
  list: (data) => get("/department/list", data),
  info: (data) => get("/department/info", data),
  save: (data) => post("/department/save", data),
  update: (data) => post("/department/update", data),
  delete: (data) => get("/department/delete", data),
};

// 班组
api.team = {
  list: (data) => get("/team/list", data),
  info: (data) => get("/team/info", data),
  save: (data) => post("/team/save", data),
  update: (data) => post("/team/update", data),
  delete: (data) => get("/team/delete", data),
};

// 员工
api.employee = {
  list: (data) => get("/employee/list", data),
  info: (data) => get("/employee/info", data),
  save: (data) => post("/employee/save", data),
  update: (data) => post("/employee/update", data),
  delete: (data) => get("/employee/delete", data),
  approvalRuzhi: (data) => get("/employee/approvalRuzhi", data),
  approvalUpdate: (data) => get("/employee/approvalUpdate", data),
  approvalQuit: (data) => get("/employee/approvalQuit", data),
  quit: (data) => get("/employee/quit", data),
  lookEmployeeInfo: (data) => post("/employee/lookEmployeeInfo", data),
  lookMonthEmployeeStudyList: (data) =>
    get("/employee/lookMonthEmployeeStudyList", data),
  uploadHeadPic: window.SITE_CONFIG["apiURL"] + "/employee/uploadSingePic",
  batchSave: (data) => post('/employee/batchSave', data)
};

// 汽车
api.car = {
  list: (data) => get("/car/list", data),
  info: (data) => get("/car/info", data),
  save: (data) => post("/car/save", data),
  update: (data) => post("/car/update", data),
  delete: (data) => get("/car/delete", data),
  approvalAdd: (data) => get("/car/approvalAdd", data),
  approvalUpdate: (data) => get("/car/approvalUpdate", data),
  approvalQuit: (data) => get("/car/approvalQuit", data),
  quit: (data) => get("/car/quit", data),
  restartEnter: (data) => get('/car/restartEnter', data),
  batchSave: (data) => post('/car/batchSave', data)
};

// 汽车维护记录
api.carmaintain = {
  list: (data) => get("/carmaintain/list", data),
  info: (data) => get("/carmaintain/info", data),
  save: (data) => post("/carmaintain/save", data),
  update: (data) => post("/carmaintain/update", data),
  delete: (data) => get("/carmaintain/delete", data),
};

// 汽车维修记录
api.carrepair = {
  list: (data) => get("/carrepair/list", data),
  info: (data) => get("/carrepair/info", data),
  save: (data) => post("/carrepair/save", data),
  update: (data) => post("/carrepair/update", data),
  delete: (data) => get("/carrepair/delete", data),
};

// 谈心谈话
api.talk = {
  list: (data) => get("/talk/list", data),
  info: (data) => get("/talk/info", data),
  save: (data) => post("/talk/save", data),
  update: (data) => post("/talk/update", data),
  delete: (data) => get("/talk/delete", data),
};

// 值班记录
api.dutylog = {
  list: (data) => get("/dutylog/list", data),
  info: (data) => get("/dutylog/info", data),
  update: (data) => post("/dutylog/update", data),
  delete: (data) => get("/dutylog/delete", data),
};

// 值班计划
api.dutyplan = {
  list: (data) => get("/dutyplan/list", data),
  info: (data) => get("/dutyplan/info", data),
  save: (data) => post("/dutyplan/save", data),
  update: (data) => post("/dutyplan/update", data),
  delete: (data) => get("/dutyplan/delete", data),
}

// 风险管理
api.danger = {
  list: (data) => get("/danger/list", data),
  info: (data) => get("/danger/info", data),
  save: (data) => post("/danger/save", data),
  update: (data) => post("/danger/update", data),
  delete: (data) => get("/danger/delete", data),
  approvalBumen: (data) => get("/danger/approvalBumen", data),
  approvalZhianGuanli: (data) => get("/danger/approvalZhianGuanli", data),
  approvalZhianFuzeren: (data) => get("/danger/approvalZhianFuzeren", data),
  archive: (data) => get("/danger/archive", data),
  modify: (data) => post("/danger/modify", data),
};

// 隐患管理
api.hidden = {
  list: (data) => get("/hidden/list", data),
  info: (data) => get("/hidden/info", data),
  save: (data) => post("/hidden/save", data),
  update: (data) => post("/hidden/update", data),
  delete: (data) => get("/hidden/delete", data),
  bmfzrApproval: (data) => get("/hidden/bmfzrApproval", data), //部门负责人审核
  zabglyApproval: (data) => get("/hidden/zabglyApproval", data), //质安部管理员审核
  zabfzrApproval: (data) => get("/hidden/zabfzrApproval", data), //质安部负责人审核
  write: (data) => post("/hidden/write", data), // 填写整改方案
  launchCheck: (data) => get("/hidden/launchCheck", data), // 发起验收申请
  check: (data) => get("/hidden/check", data), // 验收
  urge: (data) => get("/hidden/urge", data), // 督办
};

// 督办
api.urge = {
  list: (data) => get("/urge/list", data),
};

// 事故管理
api.accident = {
  list: (data) => get("/accident/list", data),
  info: (data) => get("/accident/info", data),
  save: (data) => post("/accident/save", data),
  update: (data) => post("/accident/update", data),
  delete: (data) => get("/accident/delete", data),
  archive: (data) => get("/accident/archive", data), // 归档保存
  xzbApproval: (data) => get("/accident/xzbApproval", data), // 行政部审核
  zabglyApproval: (data) => get("/accident/zabglyApproval", data), // 质安部管理员审核
  zabfzrApproval: (data) => get("/accident/zabfzrApproval", data), // 质安部负责人审核
  sbfgApproval: (data) => get("/accident/sbfgApproval", data), // 四不放过审核
};

// 部门网格
api.griddepartment = {
  list: (data) => get("/griddepartment/list", data),
  info: (data) => get("/griddepartment/info", data),
  save: (data) => post("/griddepartment/save", data),
  update: (data) => post("/griddepartment/update", data),
  delete: (data) => get("/griddepartment/delete", data),
};

// 班组网格
api.gridteam = {
  list: (data) => get("/gridteam/list", data),
  info: (data) => get("/gridteam/info", data),
  save: (data) => post("/gridteam/save", data),
  update: (data) => post("/gridteam/update", data),
  delete: (data) => get("/gridteam/delete", data),
  employeeList: (data) => get("/gridteam/employeeList", data), // 获取部门下的所有员工
  carList: (data) => get("/gridteam/carList", data), // 获取部门下的所有车辆
};

// 岗位网格
api.gridposition = {
  list: (data) => get("/gridposition/list", data),
  info: (data) => get("/gridposition/info", data),
  save: (data) => post("/gridposition/save", data),
  update: (data) => post("/gridposition/update", data),
  delete: (data) => get("/gridposition/delete", data),
};

// 公告
api.notice = {
  list: (data) => get("/notice/list", data),
  info: (data) => get("/notice/info", data),
  save: (data) => post("/notice/save", data),
  update: (data) => post("/notice/update", data),
  delete: (data) => get("/notice/delete", data),
};

// 场站
api.station = {
  list: (data) => get("/station/list", data),
  info: (data) => get("/station/info", data),
  save: (data) => post("/station/save", data),
  update: (data) => post("/station/update", data),
  delete: (data) => get("/station/delete", data),
};

// 场站巡查计划
api.patrolplan = {
  list: (data) => get("/patrolplan/list", data),
  info: (data) => get("/patrolplan/info", data),
  save: (data) => post("/patrolplan/save", data),
  update: (data) => post("/patrolplan/update", data),
  delete: (data) => get("/patrolplan/delete", data),
};

// 巡查职守
api.patrol = {
  list: (data) => get("/patrol/list", data),
  info: (data) => get("/patrol/info", data),
  save: (data) => post("/patrol/save", data),
  update: (data) => post("/patrol/update", data),
  delete: (data) => get("/patrol/delete", data),
  archive: (data) => get("/patrol/archive", data), // 审核
};

// 车辆日检/例检
api.carcheck = {
  list: (data) => get("/carcheck/list", data),
  info: (data) => get("/carcheck/info", data),
  save: (data) => post("/carcheck/save", data),
  update: (data) => post("/carcheck/update", data),
  delete: (data) => get("/carcheck/delete", data),
  archive: (data) => get("/carcheck/archive", data), // 审核
};

// 监督检查
api.supervise = {
  list: (data) => get("/supervise/list", data),
  info: (data) => get("/supervise/info", data),
  save: (data) => post("/supervise/save", data),
  update: (data) => post("/supervise/update", data),
  delete: (data) => get("/supervise/delete", data),
};

// 车辆例检计划
api.carcheckplan = {
  list: (data) => get("/carcheckplan/list", data),
  info: (data) => get("/carcheckplan/info", data),
  save: (data) => post("/carcheckplan/save", data),
  update: (data) => post("/carcheckplan/update", data),
  delete: (data) => get("/carcheckplan/delete", data),
};

// 自愿报告
api.reporting = {
  list: (data) => get("/reporting/list", data),
  info: (data) => get("/reporting/info", data),
  save: (data) => post("/reporting/save", data),
  update: (data) => post("/reporting/update", data),
  delete: (data) => get("/reporting/delete", data),
};

// 车辆能源管理
api.carenergy = {
  list: (data) => get("/carenergy/list", data),
  info: (data) => get("/carenergy/info", data),
  save: (data) => post("/carenergy/save", data),
  update: (data) => post("/carenergy/update", data),
  delete: (data) => get("/carenergy/delete", data),
};

// 公共
api.common = {
  pic: window.SITE_CONFIG["apiURL"] + "/common/pic/",
  aliHeaderPic:
    "https://zizhu-beiahng.oss-cn-shanghai.aliyuncs.com/employeeHeaderPic/",
  facePicUrl: 'https://zizhu-beiahng.oss-cn-shanghai.aliyuncs.com/employeeFacePic/',
  upload: window.SITE_CONFIG["apiURL"] + "/common/upload/",
  uploadVideo: window.SITE_CONFIG["apiURL"] + "/common/uploadVideo",
  getVideoUrl: (data) => get("/common/getVideoUrl", data),
};

export default api;
